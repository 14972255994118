
// Tree-like logic class for menu navigation
function navMenuItem(title, state, authenticate, icon){
  this.title = title;
  this.state = state;
  this.authenticate = authenticate;
  this.icon = icon;
  this.children = [];
  this.parent = null;
  this.next = null;

  this.addChild = (newChild) => {
    newChild.parent = this;
    if (this.children.length > 0) {
      this.children[this.children.length-1].next = newChild;
    }
    this.children.push(newChild);
  };
}

var home = new navMenuItem('Accueil','dashboard',['admin'], 'home');

var inventory = new navMenuItem('Inventaire','',['admin', 'management_dept', 'logistic_dept', 'salesmen_dept', 'receptionist', "shipper"], 'list');
var locations = new navMenuItem('Locations','inventory/locations',['admin', 'management_dept', 'logistic_dept', 'receptionist', "shipper"], '');
var locationCreation = new navMenuItem('Création de location','inventory/locations/location-creation',['admin', 'management_dept', 'logistic_dept', 'receptionist', "shipper"], '');
var rawMaterial = new navMenuItem('Matière première','inventory/raw-material',['admin', 'management_dept', 'logistic_dept', 'receptionist', "shipper"], '');
var finishedProduct = new navMenuItem('Produit fini','inventory/finished-product',['admin', 'management_dept', 'logistic_dept', 'salesmen_dept', 'receptionist', "shipper"], '');
var grid = new navMenuItem("Gestion de l'inventaire",'inventory/grid',['admin', 'management_dept', 'logistic_dept', 'receptionist', "shipper"], '');

var order = new navMenuItem('Commande Matériel','',['admin', 'administration_dept', 'management_dept', 'logistic_dept', 'receptionist'] , 'copy');
var orderManagement = new navMenuItem('Gestion des Matériels','orders/order-management',['admin', 'administration_dept', 'management_dept', 'logistic_dept', 'receptionist'], '');
var orderCreation = new navMenuItem('Création de Matériel','orders/order-management/order-creation',[], '');
var orderHistory = new navMenuItem('Historique des Matériels','orders/order-history',['admin', 'administration_dept', 'management_dept', 'logistic_dept', 'receptionist'], '');

var delivery = new navMenuItem('Livraison','',['admin','management_dept', "logistic_dept", "production_dept", 'receptionist'], 'paste');
var deliveryManagement = new navMenuItem('Gestion des livraisons','deliveries/delivery-management',['admin', 'management_dept', "logistic_dept", "production_dept",  'receptionist'], '');
var deliveryCreation = new navMenuItem('Création de livraison','deliveries/delivery-management/delivery-creation',[], '');
var deliveryHistory = new navMenuItem('Historique des livraisons','deliveries/delivery-history',['admin','management_dept', "logistic_dept", "production_dept", 'receptionist'], '');

var clients = new navMenuItem('Clients','',['admin', "administration_dept", "logistic_dept", "management_dept", 'receptionist'], 'user');
var clientManagement = new navMenuItem('Gestion des clients','clients/client-management',['admin', "administration_dept", "logistic_dept", "management_dept", 'receptionist'], '');
var clientCreation = new navMenuItem('Création de client','clients/client-management/client-creation',[], '');

var items = new navMenuItem('Moules','',['admin','administration_dept', "management_dept", "salesmen_dept", "mold_dept", "quality_dept", 'receptionist'], 'wrench');
var categoryManagement = new navMenuItem('Gestion catalogue matériel','items/category-management',['admin','administration_dept', "management_dept", 'receptionist'], '');
var categoryCreation = new navMenuItem('Création catalogue matériel','items/category-management/category-creation',['admin','administration_dept', "management_dept", 'receptionist'], '');
var itemManagement = new navMenuItem('Gestion des moules','items/item-management',['admin','administration_dept', "management_dept","salesmen_dept", "mold_dept",  "quality_dept", 'receptionist'], '');
var itemCreation = new navMenuItem("Création d'un moule",'items/item-management/item-creation',['admin','administration_dept',"management_dept",  "salesmen_dept", "mold_dept", "quality_dept", 'receptionist'], '');

var machineManagement = new navMenuItem("Machines",'machines',['admin','management_dept', 'receptionist'], 'dashboard');
var machineCreation = new navMenuItem('Création de machine','machines/machine-creation',['admin','management_dept', 'receptionist'], '');

var itemPlanning = new navMenuItem("Planif moules",'item-planning',['admin','management_dept', 'mold_dept', 'receptionist'], 'calendar');

var jobs = new navMenuItem('Jobs','',['admin',"management_dept",'administration_dept', "logistic_dept", "salesmen_dept", "mold_dept", "quality_dept", "production_dept",'receptionist'], 'briefcase');
var jobManagement = new navMenuItem('Gestion des jobs','jobs/job-management',['admin',"management_dept",'administration_dept', "logistic_dept", "salesmen_dept", 'receptionist'], '');
var jobCreation = new navMenuItem('Création de job','jobs/job-management/job-creation',['admin',"management_dept",'administration_dept', "logistic_dept", "salesmen_dept", 'receptionist'], '');
var machineSchedule = new navMenuItem('Horaire machine', 'jobs/machine-schedule', ['admin',"management_dept", "logistic_dept", "production_dept", 'receptionist'], '');
var jobPlanning = new navMenuItem('Planification des jobs','jobs/job-planning',['admin', "management_dept", "logistic_dept", "mold_dept", "quality_dept", "production_dept", 'receptionist'], '');
var newJobs = new navMenuItem('Nouvelles jobs','jobs/job-planning/new-jobs',['admin', "management_dept", "logistic_dept", "mold_dept", "quality_dept", 'receptionist'], '');
var jobHistory = new navMenuItem('Fin de cycle','jobs/job-history',['admin',"management_dept", "logistic_dept", 'receptionist'], '');

var mechanicPlanning = new navMenuItem("Planif mécanos", "mechanic-planning", ['admin','management_dept', 'receptionist'], 'calendar');

var accounts = new navMenuItem('Comptes','',['admin'], 'user');
var accountsManagement = new navMenuItem('Gestion des comptes','accounts/accounts-management',['admin'], '');
var accountCreation = new navMenuItem('Création de compte','accounts/account-creation',['admin'], '');

home.next = inventory;
inventory.addChild(locations);
locations.addChild(locationCreation);
inventory.addChild(rawMaterial);
inventory.addChild(finishedProduct);
inventory.addChild(grid);
inventory.next = order;

order.addChild(orderManagement);
order.addChild(orderHistory);
orderManagement.addChild(orderCreation);
order.next = delivery;

delivery.addChild(deliveryManagement);
delivery.addChild(deliveryHistory);
deliveryManagement.addChild(deliveryCreation);
delivery.next = machineManagement;

machineManagement.addChild(machineCreation);
machineManagement.next = clients;

clients.addChild(clientManagement);
clientManagement.addChild(clientCreation);
clients.next = items;

items.addChild(categoryManagement);
categoryManagement.addChild(categoryCreation);
items.addChild(itemManagement);
itemManagement.addChild(itemCreation);

items.next = itemPlanning;
itemPlanning.next = jobs;

jobs.addChild(jobManagement);
jobManagement.addChild(jobCreation);
jobs.addChild(machineSchedule);
jobs.addChild(jobPlanning);
jobPlanning.addChild(newJobs);
jobs.addChild(jobHistory);
jobs.next = mechanicPlanning;

mechanicPlanning.next = accounts;

accounts.addChild(accountsManagement);
accounts.addChild(accountCreation);

function navMenuArray() {
  var currentNode = home;
  var navArray = [];
  while(currentNode !== null) {
    navArray.push(currentNode);
    currentNode = currentNode.next;
  }
  return navArray;
}

function getStateHierarchy(state) {
  var currentNode = home;
  var visitedStates = [];
  var hierarchy = [];

  while(currentNode !== null && currentNode.state !== state) {
    visitedStates.push(currentNode.state);

    if(currentNode.children.length > 0 && visitedStates.indexOf(currentNode.children[0].state) === -1) {
      currentNode = currentNode.children[0];
    } else if (currentNode.next !== null) {
      currentNode = currentNode.next;
    } else if (currentNode.parent !== null ) {
      currentNode = currentNode.parent;
    } else {
      return hierarchy;
    }

  }

  while(currentNode !== null) {
    hierarchy.push(currentNode);
    currentNode = currentNode.parent;
  }

  return hierarchy.reverse();
}
